import React, { Component } from 'react'
import moment from 'moment'
import {
  Link
} from "react-router-dom";
import RentDetails from './bills/renting'
import UpcomingBills from './bills'
import Leasing from './bills/leasing'
import Arrease from './bills/arrears'
import Held from './bills/held'
import PropertyDetails from './bills/propertyDetails'
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import FullHouse from './../../../../component/assets/images/FullHouse.png'
import {Button, Modal} from 'react-bootstrap';
import HOME_BLACK from './../../../../component/assets/images/icons-op/home.png'
import HOME_WHITE from './../../../../component/assets/img/owner-portal/home_white.png'
import VACANCY from './../../../../component/assets/images/icons-op/for-sale.png'
import LEASES from './../../../../component/assets/images/icons-op/leased.png'
import ARREARS from './../../../../component/assets/images/icons-op/arrears.png'
import HELD from './../../../../component/assets/images/icons-op/held.png'
import Enquiry from './../../../../modules/blocks/sections/tenent/enquiry'
import BILL_UPCOMING from './../../../../component/assets/images/icons-op/bill.png'
import Message from './bills/message';
import Refer from './../panel/refer'
class FolioDetails extends Component {
    
  constructor(props)
  {
      super(props)
      this.state  = {
        rent: props.constants.RECENTRENTID == props.match.path ? true : false,
        bills: false,
        vacancies: false,
        leased: false,
        arrease: false,
        held: false,
        details: false,
        enquiry:false,
        refer:false,
        upcoming:false
      }
  }
  
  render() {

    const { props, state }  = this
    const { folioData, constants, recentRent, authData, userNotification }     = props
    const { forLease }      = folioData
 
    const { leased } = this.availability()
    const percentage = props.helper.leasedPercentage(folioData);
    const numOfProperty =  this.getTotalProperty() 
    const new_message   = (userNotification && userNotification?.length) ? userNotification?.filter( list => list?.is_viewed == 0) : 0
    return (

      <div class="row d-flex flex-wrap align-content-end h-100">
            
            <div class="col-lg-6 col-md-5 col-sm-12 col-xl-6 d-flex flex-wrap-reverse align-content-end action_section">
              {
                (!state?.enquiry && !state.bills && !state?.rent && !state.vacancies && !state.leased && !state.arrease && !state.held && !state?.refer && !state?.upcoming) &&
                <>
                  <div onClick={() => this.setState({refer:true})} className="details_box bg_black_opacity d-flex justify-content-center align-items-center flex-column cursor-pointer">
                    <i className="fa fa-user-plus details_box_img"></i>
                    <p className="details_box_text color_white">Refer a Friend</p>
                  </div>
                  <div class="details_box bg_black_opacity d-flex justify-content-center align-items-center flex-column cursor-pointer">
                    <Link to="/resources" className='d-flex justify-content-center align-items-center flex-column'>
                      <img src={HOME_WHITE} className="details_box_img" alt="" />
                      <p class="details_box_text color_white">Resources</p>
                    </Link>
                  </div>
                  <div onClick={() => this.setState({enquiry:true})} className="cursor-pointer details_box bg_gold_opacity d-flex justify-content-center align-items-center flex-column">
                    <img src={HOME_BLACK} class="details_box_img" alt="" />
                    <p className="details_box_text color_black">Owner Inquiry</p>
                  </div>
                  <div onClick={() => this.setState({upcoming:true})} className="details_box bg_black_opacity d-flex justify-content-center align-items-center flex-column position-relative cursor-pointer">
                    {
                      (new_message && new_message?.length > 0) &&
                      <p className='notification_menu'>{new_message?.length}</p>
                    }
                    <i className="fa fa-envelope"></i>
                    <p className="details_box_text color_white">Messages</p>
                  </div>
                  <div onClick={(el) => this.getLogout(el)}className="details_box bg_black_opacity d-flex justify-content-center align-items-center flex-column cursor-pointer">
                    <i class="fa fa-sign-out details_box_img"></i>
                    <p className="details_box_text color_white">Logout</p>
                  </div>
                </>
              }
            </div>
            {
                (state?.enquiry || state?.rent || state?.bills || state?.vacancies || state?.leased || state?.arrease || state.held || state?.refer || state?.upcoming) &&
                <div class="col-lg-12 col-md-12 col-sm-12 col-xl-12 d-flex flex-wrap-reverse align-content-end action_section">
                  {
                    (state?.upcoming) &&
                    <Message upcoming={state?.upcoming} {...props} onReferClose={(el) => this?.handleReferClose(el)}/>
                  }
                  {
                    (state?.refer) &&
                    <Refer refer={state?.refer} upcoming={state?.upcoming} {...props} onReferClose={(el) => this?.handleReferClose(el)}/>
                  }
                  {
                    state?.enquiry &&
                    <Enquiry {...props} onClose={() => this.setState({enquiry:false})} />
                  }
                  {
                    state?.rent &&
                    <RentDetails title="Rent Details" {...props} show={state.rent} handlePopupEvent={(el) => this.handlePopupCloseEvent(el)} />
                  }
                  {
                    state.bills &&
                    <UpcomingBills title="Upcoming Bills" {...props} show={state.bills} handlePopupEvent={(el) => this.handlePopupCloseEvent(el)} />
                  }
                  {
                    state.vacancies &&
                    <Leasing title="Vacancies" {...props} show={state.vacancies} handlePopupEvent={(el) => this.handlePopupCloseEvent(el)}  />
                  }
                  {
                    state.leased &&
                    <Leasing title="New Leases" {...props} show={state.leased} handlePopupEvent={(el) => this.handlePopupCloseEvent(el)}  />
                  }
                  {
                    state.arrease &&
                    <Arrease title="Arrears & Current Bills" {...props} show={state.arrease} handlePopupEvent={(el) => this.handlePopupCloseEvent(el)}  />
                  }
                  {
                    state.held &&
                    <Held title="FUNDS HELD" {...props} show={state.held} handlePopupEvent={(el) => this.handlePopupCloseEvent(el)}  />
                  }
                  
                </div>
            }
            {
                (!state?.enquiry && !state?.rent && !state?.bills && !state?.vacancies && !state?.leased && !state?.arrease && !state.held && !state?.refer && !state?.upcoming) &&
                <div class="col-lg-6 col-md-7 col-sm-12 col-xl-6">
                  <div class="row d-flex justify-content-end">
                    <div class="col-lg-12 col-xl-10 col-md-12">
                      <div class="customer_name">{ authData?.user_detail?.first_name+' '+authData?.user_detail?.last_name}</div>
                      <div class="row">
                      <div class="col-lg-12 col-xl-12 col-md-12">
                        <div class="d-flex snapshot_details_panel">
                          <div class="col-lg-5 col-xl-5 col-md-5 col-xm-6">
                            <div className="folio_progress text-center">
                                  <div className="total-percentage">
                                    <CircularProgressbar
                                      value={percentage}
                                      text={`${percentage}%`}
                                      background
                                      backgroundPadding={8}
                                      styles={buildStyles({
                                        backgroundColor: "#fff",
                                        fontWeight:"bold",
                                        textColor: "#000",
                                        pathColor: "#b2873f",
                                        trailColor: "transparent"
                                      })}
                                    />
                                </div>
                            </div>
                          </div>
                          <div class="col-lg-7 col-xl-7 col-md-7 col-xm-6 d-flex flex-wrap align-items-center">
                            <div class="total_doors">{ this.getTotalProperty() } Door{ (parseInt(numOfProperty) > 1) && 's'}</div>
                          </div>
                        </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-xl-6 col-md-6 col-xm-6">
                          <div class="snapshot_details_box d-flex justify-content-between cursor-pointer" onClick={() => this.setState({rent: true, bills:false,leased:false,arrease:false,held:false})}>
                            <div><img src={HOME_BLACK} class="snapshot_details_icon" /></div>
                            <div class="d-block">
                              <p class="snapshot_details_box_price">{this?.totalRent()}</p>
                              <p class="snapshot_details_box_text">Total Rent</p>
                            </div>
                          </div>
                          <div>
                              <Link to="/for-lease" class="snapshot_details_box d-flex justify-content-between">
                                <div><img src={VACANCY} class="snapshot_details_icon" /></div>
                                <div class="d-block">
                                  <p class="snapshot_details_box_price">{folioData?.forLease ? forLease : 0}</p>
                                  <p class="snapshot_details_box_text">Vacancies</p>
                                </div>
                            </Link>
                          </div>
                          <div class="snapshot_details_box d-flex justify-content-between cursor-pointer"  onClick={() => this.setState({arrease: true, rent:false,bills:false,leased:false,held:false})}>
                            <div><img src={ARREARS} class="snapshot_details_icon" /></div>
                              <div class="d-block">
                                  {/*<p className="snapshot_details_box_price">${this.totalArrears(props)}</p>*/}
                                  <p className="snapshot_details_box_price">${this.calculateArrears(props).toFixed(2)}</p>
                                  <p className="snapshot_details_box_text">Arrears</p>
                              </div>
                          </div>
                        </div>
                          <div class="col-lg-6 col-xl-6 col-md-6 col-xm-6 d-flex flex-wrap align-items-center">
                          <div class="snapshot_details_box d-flex justify-content-between cursor-pointer"  onClick={() => this.setState({bills: true, rent:false,leased:false,arrease:false,held:false})}>
                            <div className='snapshot_details_icon'><img src={BILL_UPCOMING} class="snapshot_details_icon" /></div>
                            <div class="d-block">
                              <p class="snapshot_details_box_price">${ this.totalBillDue() }</p>
                              <p class="snapshot_details_box_text">Bill Upcoming</p>
                            </div>
                          </div>
                          <div class="snapshot_details_box d-flex justify-content-between cursor-pointer"  onClick={() => this.setState({leased: true, rent:false,bills:false,arrease:false,held:false})}>
                            <div><img src={LEASES} class="snapshot_details_icon" /></div>
                            <div class="d-block">
                              <p class="snapshot_details_box_price">{leased}</p>
                              <p class="snapshot_details_box_text">New Leased</p>
                            </div>
                          </div>
                          <div class="snapshot_details_box d-flex justify-content-between cursor-pointer" onClick={() => this.setState({held: true, rent:false,bills:false,leased:false,arrease:false})}>
                            <div><img src={HELD} class="snapshot_details_icon" /></div>
                            <div class="d-block">
                              <p class="snapshot_details_box_price">{ this.fundsHeld(props) }</p>
                              <p class="snapshot_details_box_text">Held</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
            }
        </div>
    )
  }

  handleReferClose(el)
  {
    el?.preventDefault();
    this.setState({refer:false, upcoming:false})
  }

  /**
   * handle popup close event
   * 
   * @param {object} el 
   */
  handlePopupCloseEvent(el)
  {
      this.setState({
        rent: false,
        bills: false,
        vacancies: false,
        leased: false,
        arrease: false,
        held: false,
        details: false
      })
  }
  /**
   * get total property count
   * 
   * @returns {*} NA
  */
  getTotalProperty(){

    const { propertyList, group }  = this.props.folioData

    let totalProperty   = propertyList?.length

    group?.map( option => {

        totalProperty += option?.property?.length
    })

    return totalProperty
  }
  
  /**
   * get total pending bill 
   * 
   * @returns {*} NA
  */
  totalBillDue()
  {
    let totalBillDue  = 0

    const { propertyList } = this.props?.folioData
    try {
      if(propertyList)
      {
          propertyList.map( property => {

            property?.bill_due.map( bills => {
              totalBillDue  += parseFloat(bills?.amount)
            })
          })
      }
    } catch (error) {
      
    }
    return (totalBillDue) ? this.props.helper.currencySign(totalBillDue) : '0.00'
  }

  /**
   * availability
   * 
   * @param {*} NA 
   * @returns 
  */
  availability()
  {   
      let avail               = 0 
      const { props }         = this
      const { propertyList }  = props?.folioData
      
      propertyList?.map(property => {

        if( property?.tenant_lease && property?.lease?.authorize_lease ) 
          avail++;
      })
      return  { leased: avail}
  }

  /**
   * get total pending bill 
   * 
   * @returns {*} NA
  */
   totalRent()
   {
     let totalRent  = 0
 
     const { propertyList } = this.props?.folioData
     try {
       if(propertyList)
       {
          propertyList.filter( property => this.props.helper.findBuildingStatus( property ) ).map( property => {
                totalRent  += parseFloat((property?.lease?.rent).replace())
          })
       }
     } catch (error) {
       
     }
     return (totalRent) ? this.props.helper.currencySign(totalRent) : '0.00'
   }
  
  /**
   * total funds held
   * 
   * @param {object} props 
   * @returns 
  */
  fundsHeld(props){

    let fundsHeld = 0
    const { folioData, folioLedgers }   =  props

    try {
        
        folioData?.propertyList?.map(property => {

              let index  = folioLedgers?.[property?.property_id]?.length
              let ledger =  folioLedgers?.[property?.property_id][index-1]

              fundsHeld         += parseFloat(ledger?.balanceNF)
        })
    } catch (error) {}
    return props.helper.currencySign(fundsHeld)
  }

  /**
   * total arrease
   * 
   * @param {object} props 
   * @returns 
  */
  totalArrears(props){

    let arrease  = 0
    const { folioData }   =  props

    const list  = folioData?.propertyList?.filter( property => props.helper.findBuildingStatus( property ) )

    if( !list?.length ) return arrease

    list?.map(property => {
        
      if( property?.lease )
      {
        if( property?.lease?.ar?.total_debt_rent )
              arrease    += parseFloat(property?.lease?.ar?.total_debt_rent)

        if( property?.lease?.ar?.total_debt_bill )
              arrease    += parseFloat(property?.lease?.ar?.total_debt_bill)

        let property_bills = property?.tenant_all_bills?.filter( list => (list?.status == 0))
        if(property_bills?.length){
          property_bills?.filter( list => list?.owner_account_id > 0)?.map( item => {
            if( item?.total_bill_amount > 0 && item?.is_sd_invoice == 0){
              if(item?.status == 2){
                // TODO::will check if any partial bill left with any amount
                let shared_bill_amount = Math.round(((item?.total_bill_amount*item?.total_bill_percent)/100), 1)
              }
              // arrease    += parseFloat(item?.total_bill_amount)
            }
          })
        }
      }
    })
    return props.helper.currencySign(arrease)
  }

  /**
   * Calculates total arrears for both tenant bills and lease rent from a list of properties.
   */
  calculateArrears(props) {
      const { folioData }   =  props

      const currentPropertyTenantIds = folioData?.leasedProperty?.flatMap(property => property.current_tenants || []).map(tenant => tenant.tenant_id);

      let totalArrears = 0;
      let totalRentArrears = 0; // For lease rent arrears
      let totalBillArrears = 0; // For tenant bills

      // Iterate through each property in the property list
      folioData?.propertyList?.forEach(property => {
          // Compute Rent Arrears
          const rentArrears = property?.lease?.ar?.total_debt_rent ?? 0; // Default to 0 if undefined or null
          totalRentArrears += Number(rentArrears); // Ensure it's treated as a number

          // Filter tenant bills that are due and valid
          const validBills = property?.tenant_all_bills?.filter(tenantBill => {
              // Check if the bill is due on or before today
              const isDue = moment(tenantBill?.due_date).isSameOrBefore(moment(), 'day');

              // Check if the bill has a valid owner account or is marked to show in the portal
              const isValidBill = tenantBill?.owner_account_id != null || tenantBill?.show_in_owner_portal;

              // Include bills that are both due and valid
              return isDue && isValidBill && currentPropertyTenantIds.includes(tenantBill.tenant_id);
          });

          // Calculate bill arrears from valid bills
          validBills?.forEach(tenantBill => {
              if (tenantBill?.total_bill_amount > 0 && tenantBill?.is_sd_invoice == 0) {
                  // Calculate the paid amount and gather transactions directly
                  let paidAmount = 0;
                  let transactionIds = [];

                  try {
                      tenantBill?.history?.forEach(history_item => {
                          history_item?.transactions?.forEach(transaction => {
                              paidAmount += parseFloat(transaction?.amount || 0); // Safely parse transaction amount
                              transactionIds.push(transaction?.transaction_id);   // Collect transaction IDs
                          });
                      });
                  } catch (error) {
                      console.error("Error processing bill payment history:", error);
                  }

                  // Calculate total amount to be paid
                  const totalAmountToBePaid = tenantBill?.total_bill_percent > 0
                      ? ((tenantBill?.total_bill_amount * tenantBill?.total_bill_percent) / 100).toFixed(2)
                      : tenantBill?.total_bill_amount;

                  // Calculate balance and accumulate bill arrears
                  const balance = totalAmountToBePaid - paidAmount; // Use the calculated paidAmount here
                  totalBillArrears += Number(balance ?? 0); // Safely add as a number
              }
          });
      });

      totalArrears = totalRentArrears + totalBillArrears;

      return totalArrears;
  }

  /**
     * get user logout
     * 
     * @param {object} el 
     */
  getLogout(el) {
      el.preventDefault()
      const { constants} = this?.props
      this.props.userLogout()
      window.location.href = constants?.MEDIAURL+'landlord.html'
  }
}


export default FolioDetails
